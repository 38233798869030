import React, { FC } from "react";
import { CurrencyDollarIcon, XCircleIcon } from "@heroicons/react/solid";

interface DialogProps {
  open: boolean;
  dialogHandler: any;
}

const donateCopy =
  "Enjoy this free desktop clock. Use it wherever you might need a cool background, whether as just an office decoration or as a prop in your podcast. It would be awesome if you could support us with a donation so we can continue to make it better. Plus, it would mean a lot and be super appreciated. Thank you in advance for your generosity!";

const Dialog: FC<DialogProps> = ({ open, dialogHandler }) => {
  return (
    <>
      {open ? (
        <div id="mainDialog" className="dialog-container">
          <div className="dialog">
            <button
              title="Close"
              onClick={dialogHandler}
              className="close-button">
              <XCircleIcon />
            </button>
            <h1>The Clock Dimension</h1>
            <p>{donateCopy}</p>
            <a
              href="https://donate.stripe.com/3cs03EdU63HH4Tu5kl"
              target="_blank">
              <button title="Donate" className="donate-button">
                <CurrencyDollarIcon /> <span>Donate</span>
              </button>
            </a>
            <div className="leaderboard"></div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Dialog;
