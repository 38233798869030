import React, { FC } from "react";

interface SettingsProps {
  open: boolean;
  color?: string;
}

const FullScreenIcon: FC<SettingsProps> = ({ open, color }) => {
  return (
    <>
      {!open ? (
        <svg
          id="fsopenicon"
          data-name="Full Screen Open Icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          fill={color ? color : "black"}>
          <polygon points="429.36 44 329.47 144 360 144 360 189.84 466.5 83.23 466.5 180 513 180 513 0 324 0 324 44 429.36 44" />
          <polygon points="180.01 467.56 82.61 467.53 190.02 360 144 360 144 329.66 44.5 429.27 44.5 324 0 324 0 512.5 13.76 512.5 13.97 513 179.99 513 180.01 467.56" />
          <polygon points="468.5 429.86 360 321.48 360 360 322.15 360 429.27 467 324 467 324 513 512 513 512 499.35 513 498.94 513 324 468.5 324 468.5 429.86" />
          <polygon points="0 179.99 44.94 180.01 44.97 83.11 144 182.03 144 144 182.34 144 83.23 45 180 45 180 0 0 0 0 14.26 0 179.99" />
        </svg>
      ) : (
        <svg
          id="fscloseicon"
          data-name="Full Screen Close Icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          fill={color ? color : "black"}>
          <polygon points="105.36 368 0 468 0 512 36 513.84 142.5 407.23 142.5 477 189 477 189 324 35 324 35 368 105.36 368" />
          <polygon points="476.02 144.56 405.61 144.53 513.02 37 512 0 467 0 367.5 106.27 367.5 36 323 36 323 189.5 336.76 189.5 336.97 190 476 190 476.02 144.56" />
          <polygon points="147 108.36 38.5 -.02 0 0 .65 38.5 107.77 145.5 35 145.5 34.5 188.5 191.5 189 191.5 35 147 35 147 108.36" />
          <polygon points="330 477 374.94 477.02 374.97 413.11 474 512.03 512 512 512.34 474 413.23 375 476 375 476 330 330 330 330 344.26 330 477" />
        </svg>
      )}
    </>
  );
};

export default FullScreenIcon;
