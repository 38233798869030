import React, { useState, useEffect, FC } from 'react';

export const GetTime: FC<{showDate?: boolean; showTime?: boolean;}> = ({ showDate, showTime }) => {
    const [dateState, setDateState] = useState(new Date());
    useEffect(() => {
        setInterval(() => setDateState(new Date()), 30000);
    }, []);
    
    return(
    <>
        {showTime && 
            dateState.toLocaleString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
            })}
        
        
        {showDate && dateState.toLocaleDateString('en-US', {
                 month: 'short',
                 day: 'numeric',
                 year: 'numeric',
            })}
    </>
    )

}