import React, { FC } from "react";
import { XCircleIcon } from "@heroicons/react/solid";

interface SettingsProps {
  open: boolean;
  settingsHandler: any;
  ringColorHandler: any;
  ringColorName: string;
  textColorHandler: any;
  fontNameHandler: any;
  dateOn: boolean;
  dateHandler: any;
  bgVidHandler: any;
  gridHandler: any;
  gridOn: boolean;
}

const Settings: FC<SettingsProps> = ({
  open,
  settingsHandler,
  ringColorHandler,
  ringColorName,
  textColorHandler,
  fontNameHandler,
  dateOn,
  dateHandler,
  bgVidHandler,
  gridHandler,
  gridOn,
}) => {
  return (
    <>
      {open ? (
        <div id="settingsOverlay" className="overlay">
          <button
            type="button"
            id="RingColorToggle"
            className="iconButton"
            onClick={ringColorHandler}>
            Rings Color
          </button>

          <button
            id="TextColorToggle"
            className="iconButton"
            onClick={textColorHandler}>
            Text Color
          </button>
          <button
            id="FontToggle"
            className="iconButton"
            onClick={fontNameHandler}>
            Font
          </button>
          <button
            id="DateAndTimeToggle"
            className="iconButton"
            onClick={dateHandler}>
            Date {dateOn ? "On" : "Off"}
          </button>
          <button
            id="BgVidToggle"
            className="iconButton"
            onClick={bgVidHandler}>
            Background Video
          </button>
          <button id="GridToggle" className="iconButton" onClick={gridHandler}>
            Grid {gridOn ? "On" : "Off"}
          </button>
          <button className="iconButton close" onClick={settingsHandler}>
            <XCircleIcon />
          </button>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Settings;
