import { useState, useEffect } from "react";
import * as React from "react";
import "./style/App.css";
import { GetTime } from "./components/time";
import { AdjustmentsIcon, CurrencyDollarIcon } from "@heroicons/react/solid";
import FullScreenIcon from "./assets/ico/FullScreenIcon";

import Dialog from "./components/dialog";
import Settings from "./components/settings";
import { SetCookie, GetCookie } from "./components/utils";

function App() {
  const [openDialog, setOpenDialog] = useState(false);
  const [openSettings, setOpenSettings] = useState(true);
  const [ringColor, setRingColor] = useState(0);
  const [textColor, setTextColor] = useState(0);
  const [fontName, setFontName] = useState(0);
  const [dateOn, setDateOn] = useState(true);
  const [bgVid, setBgVid] = useState(0);
  const [gridOn, setGridOn] = useState(true);
  const [fsOpen, setFsOpen] = useState(false);
  const [visted, setVisited] = useState(false);

  const colorArray = [
    "white",
    "red",
    "orange",
    "yellow",
    "green",
    "blue",
    "purple",
    "transparent",
    "gray",
  ];

  const fontsArray = [
    "Orbitron,sans-serif",
    "Bebas Neue,sans-serif",
    "Exo,sans-serif",
    "Quantico,sans-serif",
    "Wallpoet,sans-serif",
    "Source Code Pro,sans-serif",
    "Space Grotesk,sans-serif",
    "Dekko,sans-serif",
    "Silkscreen,sans-serif",
    "Cute Font,sans-serif",
    "Train One,sans-serif",
    "Plaster,sans-serif",
    "Lacquer,sans-serif",
    "Geo,sans-serif",
    "Stalinist One,sans-serif",
    "Geostar Fill, sans-serif",
  ];

  const bgVidArray = ["./assets/vid/ballofpower.mp4", "./assets/vid/gears.mp4"];

  const dialogHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOpenDialog(!openDialog);
    console.log(`openDialog`, openDialog);
  };

  const settingsHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOpenSettings(!openSettings);
    console.log(`openSettings`, openSettings);
  };

  const gridHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setGridOn(!gridOn);
    console.log(`gridOn`, gridOn);
  };

  const dateHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setDateOn(!dateOn);
    console.log(`dateOn`, dateOn);
  };

  const ringColorHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    ringColor < colorArray.length - 1
      ? setRingColor(ringColor + 1)
      : setRingColor(0);
    console.log(`ringColor`, colorArray[ringColor]);
  };

  const textColorHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    textColor < colorArray.length - 1
      ? setTextColor(textColor + 1)
      : setTextColor(0);
    console.log(`textColor`, colorArray[textColor]);
  };

  const ringColorStyle = {
    r1: {
      boxShadow: `0 0 200px 5px ${colorArray[ringColor]}`,
      backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='60rem' ry='60rem' stroke='${colorArray[ringColor]}' stroke-width='25' stroke-dasharray='2%2c 6' stroke-dashoffset='32' stroke-linecap='butt'/%3e%3c/svg%3e")`,
    },
    r2: {
      borderColor: colorArray[ringColor],
      boxShadow: `0 0 20px 5px ${colorArray[ringColor]}`,
      backgroundImage: `url(
        "data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='55rem' ry='55rem' stroke='${colorArray[ringColor]}' stroke-width='25' stroke-dasharray='100%2c 200%2c 50' stroke-dashoffset='100' stroke-linecap='square'/%3e%3c/svg%3e")`,
    },
    r3: { borderColor: colorArray[ringColor] },
  } as const;

  const textStyle = {
    text: { color: colorArray[textColor], fontFamily: fontsArray[fontName] },
  } as const;

  const fontNameHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    fontName < fontsArray.length - 1
      ? setFontName(fontName + 1)
      : setFontName(0);
    console.log(`fontName`, fontsArray[fontName]);
  };

  const bgVidHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const newBgVid = bgVid < bgVidArray.length - 1 ? bgVid + 1 : 0;

    setBgVid(newBgVid);

    const theBgVid = document.querySelector("#bgVideo") as HTMLImageElement;
    theBgVid.src = require(`${bgVidArray[newBgVid]}`);

    console.log(`after bgVid ${newBgVid}`, theBgVid.src);
  };

  const FullScreenHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    !document.fullscreenElement
      ? document.documentElement
          .requestFullscreen()
          .then(() => setFsOpen(true))
          .catch((err) => {
            alert(
              `An error occurred while trying to switch into fullscreen mode: ${err.message} (${err.name})`
            );
          })
      : document.exitFullscreen().then(() => setFsOpen(false));
  };

  // const Cookie = !GetCookie("visitedOnce") && SetCookie("visitedOnce", "true");

  const year = new Date().getFullYear();

  useEffect(() => {
    const onPageLoad = () => {
      let isVideo = document.getElementById("bgVideo");
      if (!isVideo) {
        const bgV = document.createElement("video");
        bgV.id = "bgVideo";
        bgV.autoplay = true;
        bgV.loop = true;
        bgV.muted = true;

        const bgVSource = document.createElement("source");
        bgVSource.setAttribute("src", `${require(`${bgVidArray[bgVid]}`)}`);
        bgVSource.setAttribute("type", "video/mp4");

        bgV.appendChild(bgVSource);
        document.body.appendChild(bgV);
      }
    };

    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  return (
    <>
      <main className={`grid ${!gridOn ? "off" : ""}`}>
        <div id="g1" className="grid-item">
          <span>1</span>
        </div>
        <div id="g2" className="grid-item">
          <span>2</span>
        </div>
        <div id="g3" className="grid-item">
          <span>3</span>
        </div>
        <div id="g4" className="grid-item">
          <span>4</span>
        </div>

        <div id="g5" className="grid-item">
          <span>5</span>
          <div className="time">
            <div className="time-digits" style={textStyle.text}>
              <GetTime showTime />
            </div>
            <div
              className={`date-digits ${!dateOn ? "off" : ""}`}
              style={textStyle.text}>
              <GetTime showDate />
            </div>
            <Settings
              open={openSettings}
              settingsHandler={settingsHandler}
              ringColorHandler={ringColorHandler}
              ringColorName={colorArray[ringColor]}
              textColorHandler={textColorHandler}
              fontNameHandler={fontNameHandler}
              dateHandler={dateHandler}
              dateOn={dateOn}
              bgVidHandler={bgVidHandler}
              gridHandler={gridHandler}
              gridOn={gridOn}
            />
          </div>
        </div>

        <div id="g6" className="grid-item">
          <span>6</span>
        </div>
        <div id="g7" className="grid-item">
          <span>7</span>
        </div>
        <div id="g8" className="grid-item">
          <span>8</span>
        </div>
        <div id="g9" className="grid-item">
          <span>9</span>
        </div>
      </main>

      <div id="rings">
        <span className="ring1" style={ringColorStyle.r1}>
          <span className="boxy"></span>
        </span>
        <span className="ring2" style={ringColorStyle.r2}></span>
        <span className="ring3" style={ringColorStyle.r3}></span>
      </div>

      <button
        title={fsOpen ? "Exit Fullscreen" : "Fullscreen"}
        id="FullScreenButton"
        className="fssettings"
        onClick={FullScreenHandler}>
        <FullScreenIcon open={fsOpen} color="white" />
      </button>

      <footer>
        <div className="settings">
          <button
            title="Settings"
            id="SettingsButton"
            onClick={settingsHandler}>
            <AdjustmentsIcon />
          </button>
        </div>
        <small className="copyright">©{year} Intensional Form Design</small>
        <button title="Donate" id="DonateButton" onClick={dialogHandler}>
          <CurrencyDollarIcon />
        </button>
      </footer>

      <Dialog open={openDialog} dialogHandler={dialogHandler} />
    </>
  );
}

export default App;
