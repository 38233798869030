import {useState, FC} from 'react';
import { CirclePicker } from 'react-color';

export const ColorPicker: FC<{color?: string; onChange?: React.ChangeEvent<HTMLInputElement>;}> = ({color}) => {
    const [theColor, setTheColor] = useState("#555");
    
    const handleOnChangeComplete = (color:any) => {
        setTheColor(color.hex);
    }

return(
    <CirclePicker color={theColor} onChangeComplete={handleOnChangeComplete} />
)

}